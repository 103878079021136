import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import "./content.css";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { Reorder } from "framer-motion";
import { useCookies } from "react-cookie";

function Content() {
  const [cookies] = useCookies();
  const isProduction = window.location.hostname.includes("vertical-leap.tools");
  const clientContentApiUrl = isProduction ? "https://client-content-creator-api.vertical-leap.tools" : "https://localhost:7158";
  const [data, setData] = useState(JSON.parse(localStorage.getItem("data")) || []);
  const [newHeadingCount, setnewHeadingCount] = useState(JSON.parse(localStorage.getItem("newHeadingCount")) || 1);
  const [currentQuery, setCurrentQuery] = useState(JSON.parse(localStorage.getItem("currentQuery")) || "");
  const [allBriefData, setallBriefData] = useState(JSON.parse(localStorage.getItem("allBriefData")) || []);
  const [crawledURLs, setCrawledURLs] = useState(JSON.parse(localStorage.getItem("crawledURLs")) || { crawl_results: [] });
  const [serpData, setserpData] = useState(JSON.parse(localStorage.getItem("serpData")) || {});
  const [relatedQuestions, setrelatedQuestions] = useState(JSON.parse(localStorage.getItem("relatedQuestions")) || []);
  const [wordCount, setwordCount] = useState(JSON.parse(localStorage.getItem("wordCount")) || 0);
  const [examplePages, setexamplePages] = useState(JSON.parse(localStorage.getItem("examplePages")) || []);
  const [gettingData, setgettingData] = useState(false);
  const [contentSummary, setcontentSummary] = useState(JSON.parse(localStorage.getItem("contentSummary")) || "");
  const [newPath, setnewPath] = useState(JSON.parse(localStorage.getItem("newPath")) || "");
  const [clientDomain, setclientDomain] = useState(JSON.parse(localStorage.getItem("clientDomain")) || "");
  const [internalLinks, setinternalLinks] = useState(JSON.parse(localStorage.getItem("internalLinks")) || []); // Needs adding to local storage
  const [internalLinksSelected, setinternalLinksSelected] = useState(JSON.parse(localStorage.getItem("internalLinksSelected")) || []);
  const [eatLinks, seteatLinks] = useState(JSON.parse(localStorage.getItem("eatLinks")) || []); // Needs adding to local storage
  const [eatLinksSelected, seteatLinksSelected] = useState(JSON.parse(localStorage.getItem("eatLinksSelected")) || []);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const keyword = searchParams.get("q");
    if (keyword) {
      const decodedKeyword = decodeURIComponent(keyword);
      const input = document.getElementById("search-bar-content");
      input.value = decodedKeyword;
      handleSearch();
    }
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("newHeadingCount", JSON.stringify(newHeadingCount));
    localStorage.setItem("currentQuery", JSON.stringify(currentQuery));
    localStorage.setItem("allBriefData", JSON.stringify(allBriefData));
    localStorage.setItem("crawledURLs", JSON.stringify(crawledURLs));
    localStorage.setItem("serpData", JSON.stringify(serpData));
    localStorage.setItem("relatedQuestions", JSON.stringify(relatedQuestions));
    localStorage.setItem("wordCount", JSON.stringify(wordCount));
    localStorage.setItem("examplePages", JSON.stringify(examplePages));
    localStorage.setItem("contentSummary", JSON.stringify(contentSummary));
    localStorage.setItem("newPath", JSON.stringify(newPath));
    localStorage.setItem("clientDomain", JSON.stringify(clientDomain));
    localStorage.setItem("internalLinks", JSON.stringify(internalLinks));
    localStorage.setItem(
      "internalLinksSelected",
      JSON.stringify(internalLinksSelected)
    );
    localStorage.setItem("eatLinks", JSON.stringify(eatLinks));
    localStorage.setItem("eatLinksSelected", JSON.stringify(eatLinksSelected));
  }, [
    data,
    newHeadingCount,
    currentQuery,
    allBriefData,
    crawledURLs,
    serpData,
    relatedQuestions,
    wordCount,
    examplePages,
    contentSummary,
    newPath,
    clientDomain,
    internalLinks,
    internalLinksSelected,
    eatLinks,
    eatLinksSelected,
  ]);

  useEffect(() => {
    // Only update wordCount if there are at least 3 crawled URLs
    if (crawledURLs?.crawl_results?.length >= 3) {
      // Get the word counts from the first 3 crawled URLs
      const wordCounts = crawledURLs.crawl_results
        .slice(0, 3)
        .map((urlData) => urlData.wordCount);

      // Calculate the average word count
      const averageWordCount =
        wordCounts.reduce((a, b) => a + b, 0) / wordCounts.length;

      // Update the state with the new word count
      setwordCount(Math.floor(averageWordCount));
    }
  }, [crawledURLs]);

  useEffect(() => {
    const h1Item = data.find((item) => item.type === "h1");
    const h1Text = h1Item ? h1Item.text : "";

    if (h1Text) {
      let modifiedText = h1Text.toLowerCase();

      modifiedText = modifiedText.replace(/\s+/g, "-");

      // Remove numbers
      modifiedText = modifiedText.replace(/\d/g, "");

      // Remove text after a colon
      modifiedText = modifiedText.replace(/:.*/, "");

      // Remove text after a hyphen and spaces around it
      modifiedText = modifiedText.replace(/\s*-\s.*$/, "");

      // Remove content within brackets
      modifiedText = modifiedText.replace(/\s*\([^)]*\)\s*/g, "");

      // Remove non-text characters (no symbols)
      modifiedText = modifiedText.replace(/[^a-z\s-]/g, "");

      // Ensure it doesn't start or end with a hyphen
      modifiedText = modifiedText.replace(/^-+|-+$/g, "");

      // Ensure no consecutive hyphens
      modifiedText = modifiedText.replace(/-{2,}/g, "-");

      // Set the newPath state
      setnewPath(modifiedText);

      console.log(modifiedText);
    }
  }, [data]);

  const handleReset = () => {
    setallBriefData([]);
    setCrawledURLs({ crawl_results: [] });
    setserpData({});
    setData([]);
    setnewHeadingCount(1);
    setCurrentQuery("");
    setwordCount(0);
    setrelatedQuestions([]);
    setexamplePages([]);
    setcontentSummary("");
    setclientDomain("");
    setnewPath("");
    setinternalLinks([]);
    setinternalLinksSelected([]);
    seteatLinks([]);
    seteatLinksSelected([]);
    const dodoInput = document.querySelector(".dodo input");
    if (dodoInput) {
      dodoInput.value = "";
    }
  };

  function Clicked(event, type) {
    console.log(type);
    const ClickedHeading = event.target.innerText.replace(/^.*?\s/, "").trim();
    if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(ClickedHeading)) {
      return; // do nothing if the clicked element is a heading
    }
    if (!data.some((obj) => obj.text === ClickedHeading)) {
      const newHeading = {
        text: ClickedHeading,
        type: type,
      };
      setData([...data, newHeading]);
    }

    console.log(data);
    console.log(event.target.parentElement.id);
  }

  function test(name, key, type) {
    console.log(name);
    console.log(key);
    const newValue = name;
    const oldValue = key;

    setData((prevData) =>
      prevData.map((item) => {
        if (item.text === oldValue) {
          const newHeading = {
            text: newValue,
            type: type,
          };
          return newHeading;
        }
        return item;
      })
    );
    console.log(data);
  }

  function removeItem(item) {
    setData((prevData) => prevData.filter((i) => i.text !== item.text));
  }

  function addItem() {
    const newHeading = {
      text: "New Heading #" + newHeadingCount,
      type: "h2",
    };
    setData([...data, newHeading]);
    setnewHeadingCount((prevCount) => prevCount + 1);
    console.log(data);
  }

  function updateHeadingType(oldValue) {
    setData((prevData) =>
      prevData.map((item) => {
        if (item.text === oldValue.text) {
          const num = parseInt(oldValue.type.slice(1));
          let newNum;
          if (num === 6) {
            newNum = 1;
          } else {
            newNum = num + 1;
          }
          const newHeading = {
            text: item.text,
            type: "h" + newNum,
          };
          return newHeading;
        }
        return item;
      })
    );
  }

  const pushData = () => {
    if (examplePages.length === 0) {
      console.log("Example empty");
      console.log(clientDomain + newPath);
      alert(
        "There aren't any examples currently selected so the brief can't be saved. Tick the use as example box on at least one article to continue."
      );
      return;
    }

    setallBriefData((prevState) => {
      const existingData = prevState || [];
      const newData = {
        query: currentQuery,
        wordCount: wordCount,
        examples: examplePages,
        heading_structure: data,
        content_summary: contentSummary,
        url: clientDomain + newPath,
        internal_links: internalLinksSelected,
        // eat_links: eatLinksSelected
      };
      if (relatedQuestions.length > 0) {
        newData.relatedQuestions = relatedQuestions;
      }
      if (eatLinksSelected.length > 0) {
        newData.eat_links = eatLinksSelected;
      }
      return [...existingData, newData];
    });

    console.log(allBriefData);
    setCrawledURLs({ crawl_results: [] });
    setserpData({});
    setData([]);
    setnewHeadingCount(1);
    setCurrentQuery("");
    setwordCount(0);
    setrelatedQuestions([]);
    setexamplePages([]);
    setcontentSummary("");
    setnewPath("");
    setinternalLinks([]);
    setinternalLinksSelected([]);
    seteatLinks([]);
    seteatLinksSelected([]);
  };

  const createVlAuthRequestOptions = () => {
    const reqHeaders = new Headers();
    reqHeaders.set("Authorization", "Bearer " + cookies["VLAuthorization"]);

    const options = {
        headers: reqHeaders,
    };

    return options;
};
  
  async function getSerpInformation(query){
    setgettingData(true);
    
    const url = clientContentApiUrl + `/serp-results/${query}`;
    try {
        const response = await fetch(url, createVlAuthRequestOptions());
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
    }
  }

  const fetchWithTimeout = async (url, count, timeoutDuration = 12000000) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => {
      controller.abort(); // Abort the fetch request if it takes longer than the specified duration
    }, timeoutDuration);
    const fetchUrl = `${clientContentApiUrl}/crawler/?url=${encodeURIComponent(url)}&count=${count}`;
    const reqHeaders = new Headers();
    reqHeaders.set("Authorization", "Bearer " + cookies["VLAuthorization"]);

    try {
      const response = await fetch(fetchUrl, { signal: controller.signal, headers: reqHeaders });
      clearTimeout(timeout); // Clear the timeout if the fetch request completes in time
  
      if (!response.ok) {
        throw new Error(`Network response was not ok for URL: ${url} for callUrl: ${fetchUrl}` );
      }
  
      return await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleSearch = async () => {
    const input = document.querySelector(".dodo input");
    setgettingData(true);
    setCurrentQuery(input.value);

    const fetchData = async () => {
      let data = await getSerpInformation(input.value);
      if(data != null){
        setserpData(data);

        // Use Promise.all() to await all the fetch calls in parallel
        const fetches = data.organicResults.map(async (organicResult, index) => {
          return fetchWithTimeout(organicResult.link, index + 1);
        });

        const crawlResults = await Promise.all(fetches);
        const filteredCrawlResults = crawlResults.filter(
          (result) => result !== null
        );
          

        setCrawledURLs((prevState) => {
          const newCrawlResults = [
            ...prevState.crawl_results,
            ...filteredCrawlResults,
          ];
          return { ...prevState, crawl_results: newCrawlResults };
        });
        if(filteredCrawlResults.length === 0)
        {
          handleReset();
        }
      }
      else{
        handleReset();
      }
    };

    await fetchData();
    setgettingData(false);
  };

  const getInternalLinks = async () => {
    const url = new URL(clientDomain);
    const baseUrl = `${url.protocol}//${url.hostname}`;
    
    const input = "site:" + baseUrl + " " + currentQuery;
    setgettingData(true);

    const fetchData = async () => {
      try {
        let data = await getSerpInformation(input);
        setinternalLinks(data.organicResults);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setgettingData(false);
      }
    };
    
    await fetchData();
  };

  const getEATReferences = async () => {
    console.log(crawledURLs);
    const allExternalLinks = [].concat(
      ...crawledURLs.crawl_results.map((urlData) => urlData.externalLinks)
    );

    console.log(allExternalLinks);

    const filteredLinks = new Set();

    const linkOccurrences = {};

    for (const link of allExternalLinks) {
      const ignoredValues = [
        "mailto",
        "javascript",
        "facebook.com",
        "twitter.com",
        "whatsapp.com",
        "linkedin.com",
        "cookiedatabase.org",
        "youtube.com",
      ];

      if (ignoredValues.some((value) => link.includes(value))) {
        continue;
      }

      const whitelistURLContains = [
        ".edu",
        ".gov",
        ".ac",
        ".org",
        "bbc.co.uk",
        "bbc.com",
        "msn.com",
        "cnn.com",
        "nytimes.com",
        "dailymail.co.uk",
        "news.google.com",
        "theguardian.com",
        "foxnews.com",
        "news.yahoo.com",
        "finance.yahoo.com",
        "nypost.com",
        "timesofindia.com",
        "thesun.co.uk",
        "the-sun.com",
        "indiatimes.com",
        "news18.com",
        "hindustantimes.com",
        "washingtonpost.com",
        "people.com",
        "cnbc.com",
        "mirror.co.uk",
        "usatoday.com",
        "india.com",
        "ndtv.com",
        "forbes.com",
        "rt.com",
        "independent.co.uk",
        "indianexpress.com",
        "buzzfeed.com",
        "businessinsider.com",
        "wsj.com",
        "reuters.com",
        "news.com.au",
        "indiatoday.in",
        "nbcnews.com",
        "apnews.com",
        "express.co.uk",
        "abc.net.au",
        "telegraph.co.uk",
        "cbsnews.com",
        "news.sky.com",
        "bloomberg.com",
        "cosmopolitan.com",
        "newsnow.co.uk",
        "cbc.ca",
        "huffpost.com",
        "politico.com",
        "timesnownews.com",
        "thehill.com",
        "hellomagazine.com",
        "usnews.com",
      ];

      if (whitelistURLContains.some((ext) => link.includes(ext))) {
        filteredLinks.add(link);
      }

      linkOccurrences[link] = (linkOccurrences[link] || 0) + 1;
    }

    for (const link in linkOccurrences) {
      if (linkOccurrences[link] > 1) {
        filteredLinks.add(link);
      }
    }

    const uniqueLinks = Array.from(filteredLinks);

    console.log(uniqueLinks);

    seteatLinks(uniqueLinks);
  };

  function relatedQuestionsCheckbox(event) {
    console.log(event);
    if (event.target.checked) {
      serpData?.relatedQuestions && setrelatedQuestions(serpData.relatedQuestions);
    } else {
      setrelatedQuestions([]);
    }
  }

  function ExampleCheckbox(e, URL, wordCount) {
    if (e.target.checked) {
      setexamplePages((prevPages) => [
        ...prevPages,
        { url: URL, wordCount: wordCount },
      ]);
      console.log(URL, wordCount);
    } else {
      setexamplePages((prevPages) =>
        prevPages.filter((page) => page.url !== URL)
      );
    }
    console.log(examplePages);
  }

  const handleClick = async () => {
    const response = await fetch(
      "https://jackoleary.pythonanywhere.com/generate_brief",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(allBriefData),
      }
    );
    console.log(data);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    const date =
      new Date().toLocaleString("en-US", { month: "long" }) +
      " " +
      new Date().getFullYear();
    link.setAttribute("download", "Content Briefs - " + date + ".docx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    console.log(allBriefData);
    alert(
      "Reminder: You'll need to fill in the keywords and any fields highlighted yellow in the exported briefs before sending to the client."
    );
  };

  const handleInternalBrief = async () => {
    const response = await fetch(
      "https://jackoleary.pythonanywhere.com/generate_brief_internal",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(allBriefData),
      }
    );
    console.log(data);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    const date =
      new Date().toLocaleString("en-US", { month: "long" }) +
      " " +
      new Date().getFullYear();
    link.setAttribute("download", "Content Briefs - " + date + ".docx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    console.log(allBriefData);
    alert(
      "Reminder: You'll need to fill in the keywords and any fields highlighted yellow in the exported briefs before sending to the content team."
    );
  };

  const getGPTPrompt = async(combinedString) =>{
    try {
      const response = await fetch(
        clientContentApiUrl + `/call_gpt_brief_summary?headings=${encodeURIComponent(
          JSON.stringify(combinedString)
        )}`, createVlAuthRequestOptions()
      );
      return  await response.json();
    } catch (error) {
      console.error(error);
      return "Error, try again or enter this manually.";
    }
  }

  const copyGPTPrompt = async () => {
    const combinedString = data
      .map((obj) => `${obj.type}: ${obj.text}`)
      .join(", ");
    setcontentSummary("Fetching ChatGPT article summary...");
    // });
    let contentSummary = await getGPTPrompt(combinedString);
    setcontentSummary(contentSummary);
  };

  function updateContentSummary(e) {
    setcontentSummary(e.target.value);
  }

  const handlePathChange = (event) => {
    setnewPath(event.target.value);
  };

  const handleDomainChange = (event) => {
    setclientDomain(event.target.value);
  };

  function internalCheckbox(e, URL) {
    if (e.target.checked) {
      setinternalLinksSelected((prevPages) => [...prevPages, { url: URL }]);
      console.log(URL);
    } else {
      setinternalLinksSelected((prevPages) =>
        prevPages.filter((page) => page.url !== URL)
      );
    }
    console.log(internalLinksSelected);
  }

  function eatCheckbox(e, URL) {
    if (e.target.checked) {
      seteatLinksSelected((prevPages) => [...prevPages, { url: URL }]);
      console.log(URL);
    } else {
      seteatLinksSelected((prevPages) =>
        prevPages.filter((page) => page.url !== URL)
      );
    }
    console.log(eatLinksSelected);
  }

  return (
    <div>
      <div className="dodo">
        <h1>Content Briefs</h1>
        {crawledURLs.crawl_results?.length !== 0 && (
          <Button variant="danger" onClick={handleReset}>
            Reset
          </Button>
        )}
      </div>

      {currentQuery === "" && (
        <>
          <p>Enter your primary keyword in the search box below.</p>
          <div className="dodo">
            <Form.Control
              type=""
              placeholder="Enter search term"
              id="search-bar-content"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <Button className="btn-vl" onClick={handleSearch}>
              Search
            </Button>
          </div>
        </>
      )}

      {gettingData ? (
        <>
          <div className="dodo">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
          <p>Calling Google and crawling the top 10 results.</p>
          <p>This can take up to 60 seconds.</p>
        </>
      ) : null}

      <div className="all-content-output">
        <div className="headings-lists">
          {crawledURLs.crawl_results?.map((urlObj, index) => (
            <div key={index}>
              {urlObj.headings.length > 0 && (
                <ListGroup>
                  <p>#{urlObj.count}</p>
                  <a
                    href={urlObj.url}
                    target="_blank"
                    rel="noreferrer"
                    id="link-to-page"
                  >
                    <p id="link-to-page">{urlObj.url}</p>
                  </a>
                  <Form>
                    <label className="checkbox-design">
                      Use as example
                      <Form.Check
                        type="checkbox"
                        onChange={(e) =>
                          ExampleCheckbox(e, urlObj.url, urlObj.wordCount)
                        }
                      />
                    </label>
                  </Form>
                  {urlObj.headings.map((heading, headingIndex) => (
                    <ListGroup.Item
                      key={`${index}-${headingIndex}`}
                      onClick={(event) => Clicked(event, heading.type)}
                    >
                      <Badge pill className={`badge-${heading.type}`}>
                        {heading.type}
                      </Badge>

                      {heading.text}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
              {urlObj.headings.length > 0 && <p>{urlObj.wordCount} words</p>}
            </div>
          ))}
        </div>

        {crawledURLs.crawl_results?.length !== 0 && (
          <div className="user-selected-content">
            <div>
              <h3>Content brief structure</h3>
              <p>Drag and drop article structure below.</p>
              <p>The h1 will be set as the article title.</p>
              <ListGroup>
                <Reorder.Group values={data} onReorder={setData}>
                  {data.map((item) => (
                    <Reorder.Item key={item.text} value={item}>
                      <div className="inside-new">
                        <Badge
                          pill
                          className={`badge-${item.type} cursor-link`}
                          onClick={() => updateHeadingType(item)}
                        >
                          {item.type}
                        </Badge>
                        <ListGroup.Item
                          contentEditable
                          className="cursor-draggable"
                          suppressContentEditableWarning={true}
                          onBlur={(e) =>
                            test(
                              e.currentTarget.textContent,
                              item.text,
                              item.type
                            )
                          }
                        >
                          {item.text}
                        </ListGroup.Item>

                        <Button
                          variant="danger"
                          id="remove-content"
                          onClick={() => removeItem(item)}
                        >
                          X
                        </Button>
                      </div>
                    </Reorder.Item>
                  ))}
                  <div className="dodo">
                    <Button variant="success" onClick={addItem}>
                      +
                    </Button>
                  </div>
                </Reorder.Group>
              </ListGroup>

              {serpData?.relatedQuestions && (
                <div id="relatedQuestions-section">
                  <h3>People Also Ask</h3>
                  {serpData?.relatedQuestions?.map((question, index) => (
                    <p key={index}>{question.question}</p>
                  ))}

                  <Form>
                    <Form.Check
                      type="switch"
                      label="Add relatedQuestions to brief"
                      onChange={(e) => relatedQuestionsCheckbox(e)}
                    />
                  </Form>
                </div>
              )}

              <h3>Article summary (optional)</h3>

              <p>
                Use the below section to generate an article summary with target
                audience.
              </p>
              <div className="dodo">
                <Button onClick={() => copyGPTPrompt()}>
                  Get ChatGPT Summary
                </Button>
              </div>
              <div id="summary-section">
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    id="gpt-prompt-textarea"
                    value={contentSummary}
                    rows={12}
                    onChange={(e) => updateContentSummary(e)}
                  />
                </Form.Group>
              </div>

              <h3>Content URL</h3>

              <Form>
                <Form.Group className="mb-3" controlId="formDomain">
                  <Form.Label>
                    Client Domain & Blog Directory (With / at end)
                  </Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="E.g. https://www.vertical-leap.uk/blog/"
                    value={clientDomain}
                    onChange={handleDomainChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPage">
                  <Form.Label>Content location</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Enter folder (without domain)"
                    value={newPath}
                    onChange={handlePathChange}
                  />
                </Form.Group>
              </Form>
              <p>New Content URL: {clientDomain + newPath}</p>

              <h3>Internal Links</h3>
              <p>
                Fetch the top related internal pages on the website that are
                indexed and may be relevant enough to include links to and from
                the new content.
              </p>
              <div className="dodo">
                <Button onClick={() => getInternalLinks()}>
                  Fetch Internal Links
                </Button>
              </div>

              <Form className="link-no-overflow">
                {internalLinks.map((url) => (
                  <div key={url} className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id={url}
                      label={
                        <span>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {url}
                          </a>
                        </span>
                      }
                      onChange={(e) => internalCheckbox(e, url)}
                    />
                  </div>
                ))}
              </Form>

              <h3>Reference links</h3>
              <p>
                Get external links from the competing content that may be useful
                to include in a new article.
              </p>
              <div className="dodo">
                <Button onClick={() => getEATReferences()}>
                  Get Reference Links
                </Button>
              </div>

              <Form className="link-no-overflow">
                {eatLinks.map((url) => (
                  <div key={url} className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id={url}
                      label={
                        <span>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {url}
                          </a>
                        </span>
                      }
                      onChange={(e) => eatCheckbox(e, url)}
                    />
                  </div>
                ))}
              </Form>

              <div className="dodo">
                <Button variant="success" onClick={() => pushData()}>
                  Save Brief
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      {allBriefData.length !== 0 && (
        <div>
          <div>
            <h3>Current briefs in document</h3>
            <p>
              The following are your saved briefs so far. These will be included
              in the DOCX export.
            </p>
            <p>
              You can continue adding more briefs to the same document or export
              them now.
            </p>
            <ol>
              {allBriefData.map((brief) => (
                <li key={brief.query}>{brief.query}</li>
              ))}
            </ol>
            <p>Select your output file version for download:</p>
            <div className="dodo">
              <Button onClick={handleClick}>For Client</Button>
              <Button onClick={handleInternalBrief}>For Content Team</Button>
            </div>
            <div className="dodo">
              <Button variant="danger" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Content;
